export const MAPBOX_API_KEY = "pk.eyJ1IjoiZGFuaXlhYWxiZWciLCJhIjoiY2s4aWh5bjFrMDAycTNnbzI0b2kyaGpkcSJ9.NIfkmDVf4rwUS3BWhyukXg"
export const GOOGLE_API_KEY = "AIzaSyBvMCxncTzV22ZvkCerSyTjvopzKwH_w0k"
export const production = true
export const API = production ? "ministryofchange.org/api" : "localhost:8000/api"
export const CLIENT = production ? "ministryofchange.org" : "localhost:3000"


export const rootURL = (production) => {
  if (production) {
    return "https://"
  } else {
    return "http://"
  }
}

